import React from 'react';
import { SubpageLayout } from '../components/SubpageLayout';
import { Seo } from '../components/Seo';

const PrivacyPage = () => (
  <SubpageLayout title="Polityka prywatności">
    <Seo
      title="Polityka prywatności"
    />
    <div className={'mx-auto mb-24'}>
      <div className="content content-color text-sm">

        <p className="clearance-after">Dokładamy wszelkich starań, aby świadczone usługi były na najwyższym poziomie,
          dlatego dane, które nam
          udostępniacie są bezpieczne. Cała nasza polityka prywatności, ochrony danych osobowych oraz plików cookies
          jest zgodna z Ogólnym Rozporządzeniem Parlamentu Europejskiego i Rady o ochronie danych osobowych nr 2016/679
          z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
          sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, znana również, jako RODO, ustawą
          z dnia 10.05.2018 r. o ochronie danych osobowych, ustawa z dnia 18 lipca 2002r. o świadczeniu usług drogą
          elektroniczną, oraz w ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne.</p>

        <h3 className="font-bold clearance-after">Zakres danych oraz moment ich zbierania</h3>

        <p className="clearance-after">W chwili rejestracji i zakładania konta w Serwisie, Użytkownik jest proszony o
          podanie danych. Użytkownik
          przekazuje również dane osobowe w związku z korzystaniem z Serwisu. Danymi podawanymi przy rejestracji są dane
          dotyczące obsługi konta w Serwisie, takie jak: adres email Użytkownika, login (generowany na podstawie adresu
          mailowego Użytkownika) oraz hasło.</p>
        <p className="clearance-after">Aby korzystać z podstawowych usług Serwisu, wystawiania faktur na podstawie
          danych wpisanych na stronie
          Serwisu przez Użytkownika, Użytkownik przekazuje takie dane jak: Nazwa firmy; Imię; Nazwisko; Numer
          identyfikacji podatkowej.</p>
        <p className="clearance-after">Operator Serwisu wykorzystuje dodatkowe dane, które mogą być udostępniane przez
          Użytkownika lub jego
          urządzenie w celu świadczenia kompleksowych usług oraz gwarancji dostępu do oferowanych funkcji Serwisu w
          obrębie przeglądarki i Aplikacji Mobilnej.</p>
        <p className="clearance-after">Operator Serwisu przetwarza dane pobierane w procesie interakcji urządzenia
          Użytkownika z przeglądarką, które
          to dane zostały opisane w Polityka Cookies i znaczniki pikselowe.</p>

        <ol className="list-decimal clearance-after ml-8">
          <li>Czas przetwarzania danych Użytkownika oraz danych konta przez Operatora Serwisu nie
            jest dłuższy niż wymaga tego zapewnienie poprawnego świadczenia Usługi, tj. tylko przez okres, w którym
            będzie
            do tego podstawa prawna, a wiec do momentu w którym:
          </li>
        </ol>
        <ul className="ml-12 list-disc">

          <li>przestanie na Administratorze danych ciążyć prawny obowiązek zobowiązujący do przetwarzania danych
            osobowych
            Użytkownika
          </li>
          <li> ustanie możliwość dochodzenia roszczeń związanych z zawartą przez strony umową;</li>
          <li className="clearance-after">zgoda zostanie cofnięta, jeśli zgoda ta była podstawą przetwarzania;</li>
        </ul>
        <p className="clearance-after">w zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi
          później.</p>
        <p className="clearance-after">Podczas zakładania konta w Serwisie oraz korzystania z Witryny lub Serwisu
          Operator Serwisu informuje
          Użytkowników o gromadzeniu i wykorzystywaniu danych. Tam gdzie jest to wymagane, Użytkownik proszony jest o
          wyrażenie zgody na wskazane działania Operatora Serwisu.</p>
        <p className="clearance-after">Operator Serwisu zbiera dane za pomocą technik marketingowych, w tym Google
          Analytics, do analizy ruchu w
          Internecie pozwalającego Operatorowi na uzyskanie dogłębnej analizy w czasie rzeczywistym jak ich witryna
          jest
          używana, jak bardzo i przez kogo. Jak również pozwala zbierać informację o tym jak użytkownicy znajdują
          Serwis, jak się po nim poruszają, jak długo na nim pozostają, gdzie udają się dalej z Serwisu. Google
          Analytics to narzędzie do przetwarzania danych użytkowników.</p>
        <p className="clearance-after">Wszystkie te usługi i funkcje wspomniane powyżej są przykładem usług stron
          trzecich w Serwisie Operatora.
          Umieszczają one pliki cookies w przeglądarkach Użytkowników kiedy odwiedzają oni Serwis. Użytkownicy są
          informowani w jasny i zrozumiały sposób jak ich dane będą używane.</p>
        <p className="clearance-after">Zgoda Użytkowników na użycie plików Cookies jest zgodna z RODO, wydana na
          podstawie prostych i konkretnych
          informacji o typach danych i ich przeznaczeniu, wydana przed przetwarzaniem jakichkolwiek danych innych niż
          ściśle wymagane, co jest znane jako ‚uprzednia zgoda’ (eng. ‚prior consent’), wydana dobrowolnie jako
          pozytywna akcja, rezultatem prawdziwego wyboru. Użytkownik ma możliwość odrzucenia zbędnych cookies i nadal
          możliwość używania strony. Zgoda jest zachowana jako dowód wyrażenia zgody, odwracalna. Użytkownik ma
          możliwość anulowania zgody kiedykolwiek ma na to ochotę.</p>
        <p className="clearance-after">Operator Serwisu za pośrednictwem portalu Facebook zbiera dane ze zgłoszeń
          Użytkowników oraz za pomocą
          technik marketingowych Lead Ads.</p>
        <p className="clearance-after">Użytkownicy akceptując politykę prywatności, wyrażają zgodę na zbieranie przez
          operatora zgłoszeń
          użytkowników na portalu Facebook, używanie Lead Ads oraz Google Analytics.</p>

        <h3 className="clearance-after font-bold">Cel przetwarzania danych</h3>

        <p className="clearance-after">Dane zbierane przez Operatora Serwisu podczas korzystania z Witryny, Serwisu lub
          Aplikacji Mobilnej są
          przetwarzane w poniżej określonych zakresie i celach:</p>

        <ul className="pl-5 list-disc clearance-after">
          <li>Świadczenia Usługi – dane Użytkownika będą przetwarzane w celu zapewnienia poprawnego funkcjonowania
            Usługi,
            w celach rozliczeń za Abonament oraz przesyłania dokumentów, linków weryfikacyjnych, wiadomości
            autoryzujących
            dostęp do Serwisu oraz powiadomień systemowych.
          </li>
          <li>Kontaktu z działem obsługi klienta i świadczenia pomocy technicznej- dane Użytkownika będą wykorzystywane
            w
            celach kontaktu z klientem oraz udzielenia mu pomocy technicznej. Podczas obsługi klienta oraz udzielania
            pomocy technicznej Operator Serwisu może wykorzystać dostęp do wszelkich danych, znajdujących się w zakładce
            Ustawienia, między innymi: danych ustawień konta, danych firm i działów, wybranego Planu Abonamentowy,
            Okresu
            Rozliczeniowego, a także faktur wystawionych przez Operatora Serwisu na rzecz Użytkownika, płatności,
            informacji o przeglądarce, integracjach, API tokenie, aktywnościach Użytkownika, danych z konta Użytkownika
            czy danych o programach partnerskich. Użytkownik dodatkowo może udzielić obsłudze technicznej Systemu
            dostępu
            do danych powierzanych, tj. zakładek: Pulpit, Klienci, Faktury, Faktury Cykliczne, Środki Pieniężne, Twoja
            Firma, Magazyn, Płatności, Raporty, Import/Export Danych
          </li>
          <li>Fakturowania i księgowania transakcji oraz prowadzenia działalności – dane Użytkownika będą wykorzystane
            do
            wystawienia mu faktury za zakupioną Usługę. Dane będą przetwarzane w celach archiwizacji dokumentów przez
            okres zgodny z przepisami prawa polskiego i unijnego, w tym Rozporządzenia. Dane te będą przechowywane w
            należyty sposób i chronione przed niedozwolonymi zmianami, nieupoważnionym rozpowszechnianiem, uszkodzeniem
            lub zniszczeniem. Dane mogą zostać wykorzystane w celach realizacji funkcji audytowych oraz na inne potrzeby
            wewnętrzne, takie jak diagnostyka problemów technicznych oraz administracja Serwisu oraz Aplikacji Mobilnej.
          </li>
          <li>Wysyłania informacji o Usłudze i informacji handlowej oraz innych celach związanych z promocją – dane
            Użytkownika będą wykorzystywane do przesyłania informacji o Usłudze: wprowadzanych nowych funkcjach i
            integracjach Serwisu, informacji o zmianach prawnych, zmianach systemowych oraz do przesyłania informacji
            handlowej, informowania Użytkownika o aktualnych rabatach oraz akcjach promocyjnych w Serwisie, a także
            informowania o rozwiązaniach podmiotów współpracujących z Operatorem Serwisu. Operator Serwisu może
            wykorzystać posiadane dane w celach personalizacji komunikacji na temat usług, które mogą być dla
            Użytkownika
            interesujące. Użytkownikowi przysługują wszelkie prawa dotyczące jego danych określone w Rozporządzeniu. W
            celu skorzystania z przysługujących praw, Użytkownik powinien skontaktować się z Operatorem Serwisu przy
            użyciu danych kontaktowych wskazanych w §18 lub §19 niniejszego Regulaminu. W przypadkach, w których zgodnie
            z
            prawem Operator Serwisu ma obowiązek uzyskać zgodę na przesyłanie korespondencji, Użytkownik zostanie
            poproszony o wyrażenie zgody na przesyłanie materiałów marketingowych.
          </li>
          <li>Zapewnienia bezpieczeństwa Użytkownikom Witryny, Serwisu i Aplikacji Mobilnej oraz ochrony praw, mienia i
            własności Operatora Serwisu – dane o sposobie korzystania z Witryny, Serwisu lub Aplikacji Mobilnej mogą
            zostać wykorzystane przez Operatora Serwisu w celu zapobiegania oraz wykrywania nadużyć, oszustw, łamania
            prawa oraz postanowień Regulaminu, a także odpowiadania na kierowane do Operatora Serwisu żądania ze strony
            uprawnionych organów państwowych oraz w celach zachowania zgodności funkcjonowania działalności Operatora
            Serwisu z obowiązującymi przepisami prawa.
          </li>
          <li>Tworzenia statystyk oraz rozwoju Serwisu – Operator Serwisu wykorzystuje dane dotyczące sposobu
            korzystania
            z Witryny, Serwisu lub Aplikacji Mobilnej przez Użytkowników w celu lepszego zrozumienia ich zachowań oraz
            preferencji. Na podstawie tych danych Operator Serwisu tworzy statystyki oraz stara się zrozumieć jaki
            sposób
            przedstawienia Usługi będzie dla Użytkownika satysfakcjonujący oraz atrakcyjny.
          </li>
          <li>Umożliwienia korzystania z integracji z innymi systemami – Operator Serwisu przetwarza dane dostępowe dla
            aplikacji zintegrowanych z Serwisem w celu przekazywania informacji (np. treści dokumentów w przypadku
            integracji z systemem CRM , kwot płatności w przypadku integracji Płatności Online, etc.) do tych aplikacji,
            zgodnie z zasadami działania uruchamianej przez Użytkownika integracji.
          </li>
        </ul>

        <p className="clearance-after">Inne cele – Operator Serwisu może wykorzystywać dane osobowe Użytkowników w inny
          sposób, o czym Użytkownik
          będzie każdorazowo informowany, a w przypadku gdy obowiązujące prawo wymaga od Operatora Serwisu posiadania
          dodatkowej zgody na przetwarzanie danych, poprosi on Użytkownika o jej wyrażenie.</p>
        <p className="clearance-after">Operator Serwisu w celu zabezpieczenia przetwarzanych danych stosuje środki
          organizacyjne i techniczne zgodne
          z przepisami prawa polskiego i unijnego w tym Rozporządzeniem oraz połączenia szyfrowane przez protokół
          SSL.</p>

        <h3 className="clearance-after font-bold">Warunek podania danych osobowych</h3>

        <p className="clearance-after">Niepodanie danych osobowych, w zależności od celu, w jakim są podawane skutkuje
          brakiem możliwości
          korzystania z usług oferowanych przez Operatora Serwisu, brak możliwości otrzymywania informacji o promocjach
          lub ofertach specjalnych oferowanych przez Operatora.</p>

        <h3 className="clearance-after font-bold">
          Uprawnienia przysługujące Użytkownikowi w związku z przetwarzaniem danych osobowych
        </h3>

        <p className="clearance-after">W związku z przetwarzaniem przez Operatora Serwisu danych osobowych,
          Użytkownikowi przysługują poniższe
          uprawnienia, które może zrealizować zgodnie z zasadami opisanymi w art. 16 – 21 RODO, kontaktując się z
          administratorem danych osobowych naszego Serwisu. Wszystkie otrzymane zgłoszenia potraktowane będą ze
          szczególną uwagą oraz zrealizowane w możliwie najkrótszym czasie.</p>

        <ul className="pl-5 list-disc clearance-after">
          <li>Prawo do żądania dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia
            przetwarzania.
          </li>
          <li>Prawo do wniesienia sprzeciwu wobec przetwarzania.</li>
          <li>Prawo do przenoszenia danych osobowych.</li>
          <li>Prawo do cofnięcia zgody na przetwarzanie danych osobowych w określonym celu, jeżeli uprzednio wyraziłeś
            taką zgodę.
          </li>
          <li>Prawo do wniesienia skargi do organu nadzorczego w związku z przetwarzaniem przez Operatora Serwisu Twoich
            danych osobowych.
          </li>
        </ul>

        <h3 className="clearance-after font-bold">Środki ochrony danych osobowych</h3>

        <p className="clearance-after">Informacje o użytkowniku są przetwarzane przez ADO z zachowaniem odpowiednich
          środków technicznych i organizacyjnych spełniających wymagania polskiego prawa, w szczególności ustawy o
          ochronie danych osobowych oraz rozporządzeniach wydanych na jej podstawie. Podstawowym środkiem ochrony jest
          stosowanie bezpiecznych połączeń (np. protokołów SSL), szyfrowanie danych oraz stosowanie innych rozwiązań
          technicznych Środki te mają na celu przede wszystkim zabezpieczenie danych osobowych użytkowników przed
          dostępem osób nieupoważnionych w celu zachowania ich bezpieczeństwa. W szczególności dostęp do danych
          osobowych użytkowników mają dostęp jedynie upoważnione osoby (pracownicy i zleceniobiorcy ADO), którzy są
          zobowiązani do zachowania tych danych w tajemnicy. Zbiór danych osobowych użytkowników Sklepu (potencjalnych
          klientów) służyć może do tworzenia bazy danych i jest traktowany jako baza danych osobowych.</p>

        <h3 className="clearance-after font-bold">Kontakt z Administratorem Ochrony Danych Osobowych</h3>

        <p className="clearance-after">
          Fakturuj&nbsp;sp.&nbsp;z&nbsp;o.o.<br/>
          ul.&nbsp;Jana&nbsp;z&nbsp;Kolna&nbsp;11,<br/>
          80-864&nbsp;Gdańsk <br/>
          <br/>
          E mail: <a href="mailto:pomoc@fakturuj.to" className="hover:text-gray-500">pomoc@fakturuj.to</a>
          &nbsp;(w tytule wiadomości prosimy o dopisek „RODO”).
        </p>

        <h3 className="font-bold clearance-after">Postanowienia końcowe</h3>

        <p className="clearance-after">Operator Serwisu zastrzega sobie prawo do wprowadzania zmian lub uzupełnień do
          niniejszej Polityki Prywatności. Wszelkie tego rodzaju zmiany będą publikowane na stronie&nbsp;
          <a href="/polityka-prywatnosci" className="hover:text-gray-500">www.fakturuj.to/polityka-prywatnosci</a>
        </p>
      </div>
    </div>
  </SubpageLayout>
);

export default PrivacyPage;
